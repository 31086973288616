<template>
    <v-dialog v-model="show_dialog" :persistent="true" max-width="1264px" scrollable>
        <v-card class="custom_card">

            <v-card-title>
                <span class="headline">{{header}}</span>
                <v-spacer></v-spacer>
                <span class="headline mt-3 mt-sm-0">{{$t('incidents.code')}} : {{item.code}}</span>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text id="manage_incident_page">
                <div v-if="loading_data" class="pb-4">
                    <v-container fluid class="incident_loading"
                                 :class="[loading_data ? 'justify-center fill-height' : '']">
                        <v-progress-circular :size="50" indeterminate color="primary"></v-progress-circular>
                    </v-container>
                </div>
                <div v-else class="pb-4">
                    <v-card class="incident_tabs">

                        <!--Tabs-->
                        <v-tabs v-model="tab" background-color="primary" dark class="elevation-1 incident_tab">
                            <v-tabs-slider color="secondary"></v-tabs-slider>

                            <v-tab href="#tab_1">
                                <v-icon class="mr-2" size="18">mdi-phone</v-icon>
                                {{$t('general.secretary_tab')}}
                            </v-tab>

                            <v-tab href="#tab_2">
                                <v-icon class="mr-2" size="18">mdi-stethoscope</v-icon>
                                {{$t('general.doctor_tab')}}
                            </v-tab>

                            <v-tab v-if="isSuperAdmin" href="#tab_3">
                                <v-icon class="mr-2" size="18">mdi-shield-account</v-icon>
                                {{$t('general.superadmin_tab')}}
                            </v-tab>
                        </v-tabs>

                        <!--Tabs items-->
                        <v-tabs-items v-model="tab" class="incident_tab_items">

                            <!--Secretary tab-->
                            <v-tab-item value="tab_1">
                                <v-card flat>
                                    <v-card-text>

                                        <v-row dense>
                                            <v-col cols="12">
                                                <v-switch v-if="action === 'create'" v-model="item.is_cash"
                                                          class="custom_switch"
                                                          :label="isCash ? $t('incidents.cash') : $t('incidents.insurance')">
                                                </v-switch>
                                            </v-col>
                                        </v-row>

                                        <!--General info-->
                                        <v-row dense>
                                            <v-col cols="12">
                                                <div class="title mb-4">{{$t('general.info')}}</div>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="reference_number" type="text" class="custom_field"
                                                              v-model="item.reference_number"
                                                              :label="$t('general.reference_number')" outlined clearable
                                                              :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="insurance_reference_number" type="text"
                                                              class="custom_field"
                                                              v-model="item.insurance_reference_number"
                                                              :label="$t('general.insurance_reference_number')" outlined
                                                              clearable :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <!--Patient info-->
                                        <v-row dense>
                                            <v-col cols="12">
                                                <div class="title mb-4">{{$t('general.patient_info')}}</div>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="patient_first_name" type="text" class="custom_field"
                                                              v-model="item.patient_first_name"
                                                              :label="$t('general.patient_first_name')" outlined
                                                              clearable
                                                              :disabled="isFieldsDisabled"
                                                              @input="$v.item.patient_first_name.$touch()"
                                                              @blur="$v.item.patient_first_name.$touch()"
                                                              :error-messages="fieldErrors('patient_first_name')">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="patient_last_name" type="text" class="custom_field"
                                                              v-model="item.patient_last_name"
                                                              :label="$t('general.patient_last_name')" outlined
                                                              clearable
                                                              :disabled="isFieldsDisabled"
                                                              @input="$v.item.patient_last_name.$touch()"
                                                              @blur="$v.item.patient_last_name.$touch()"
                                                              :error-messages="fieldErrors('patient_last_name')">
                                                </v-text-field>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-menu v-model="patient_dob_menu" :close-on-content-click="false"
                                                        max-width="290">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field name="patient_dob" type="text"
                                                                      class="custom_field"
                                                                      :value="$Helper.methods.formatDate(item.patient_dob)"
                                                                      :label="$t('general.dob')" outlined clearable
                                                                      readonly
                                                                      :disabled="isFieldsDisabled" v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="item.patient_dob" :locale="locale" no-title
                                                                   @change="patient_dob_menu = false">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-select name="gender_id" class="custom_field" v-model="item.gender_id"
                                                          :items="genders" :label="$t('general.gender')"
                                                          :item-text="`name_${$i18n.locale}`" item-value="id" outlined
                                                          clearable :disabled="isFieldsDisabled">
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4" lg="3">
                                                <v-autocomplete name="country_id" class="custom_field"
                                                                v-model="item.country_id" :items="countries"
                                                                :label="$t('general.country')"
                                                                :item-text="`name_${$i18n.locale}`" item-value="id"
                                                                outlined
                                                                clearable :disabled="isFieldsDisabled">
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="passport_number" type="text" class="custom_field"
                                                              v-model="item.passport_number"
                                                              :label="$t('general.passport_number')"
                                                              outlined clearable :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="address" type="text" class="custom_field"
                                                              v-model="item.address" :label="$t('general.address')"
                                                              outlined
                                                              clearable :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="phone_number" type="text" class="custom_field"
                                                              v-model="item.phone_number"
                                                              :label="$t('general.phone_number')"
                                                              :prefix="phoneNumberCode" outlined clearable
                                                              :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="email" type="text" class="custom_field"
                                                              v-model="item.email" :label="$t('general.email')" outlined
                                                              clearable :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <!--Insurance info-->
                                        <v-row dense>
                                            <v-col cols="12">
                                                <div class="title mb-4">{{$t('general.insurance_info')}}</div>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4" lg="3">
                                                <v-menu v-model="incident_date_menu" :close-on-content-click="false"
                                                        max-width="290">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field name="date" type="text" class="custom_field"
                                                                      :value="$Helper.methods.formatDate(item.date)"
                                                                      :label="$t('incidents.date')" outlined
                                                                      :disabled="isFieldsDisabled" v-on="on"
                                                                      @blur="$v.item.date.$touch()"
                                                                      @input="dateFieldCleared('date')"
                                                                      :error-messages="fieldErrors('date')">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="item.date" :locale="locale" no-title
                                                                   @change="incident_date_menu = false">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-select name="insurance_company_id" class="custom_field"
                                                          v-model="item.insurance_company_id"
                                                          :items="insurance_companies"
                                                          :label="$t('insurance_companies.single')"
                                                          :item-text="`name_${$i18n.locale}`" item-value="id" outlined
                                                          clearable :disabled="isFieldsDisabled">
                                                </v-select>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="policy_number" type="text" class="custom_field"
                                                              v-model="item.policy_number"
                                                              :label="$t('general.policy_number')"
                                                              outlined clearable :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-select name="policy_type_id" class="custom_field"
                                                          v-model="item.policy_type_id" :items="policy_types"
                                                          :label="$t('general.policy_type')"
                                                          :item-text="`name_${$i18n.locale}`" item-value="id" outlined
                                                          clearable :disabled="isFieldsDisabled">
                                                </v-select>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-menu v-model="insurance_start_date_menu"
                                                        :close-on-content-click="false"
                                                        max-width="290">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field name="insurance_start_date" type="text"
                                                                      class="custom_field"
                                                                      :value="$Helper.methods.formatDate(item.insurance_start_date)"
                                                                      :label="$t('incidents.insurance_start_date')"
                                                                      outlined
                                                                      clearable readonly :disabled="isFieldsDisabled"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="item.insurance_start_date" :locale="locale"
                                                                   no-title @change="insurance_start_date_menu = false">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-menu v-model="insurance_end_date_menu"
                                                        :close-on-content-click="false"
                                                        max-width="290">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field name="insurance_end_date" type="text"
                                                                      class="custom_field"
                                                                      :value="$Helper.methods.formatDate(item.insurance_end_date)"
                                                                      :label="$t('incidents.insurance_end_date')"
                                                                      outlined
                                                                      clearable readonly :disabled="isFieldsDisabled"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="item.insurance_end_date" :locale="locale"
                                                                   no-title @change="insurance_end_date_menu = false">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-select name="tour_guide_id" class="custom_field"
                                                          v-model="item.tour_guide_id" :items="tour_guides"
                                                          :label="$t('general.tour_guide')"
                                                          :item-text="`name_${$i18n.locale}`" item-value="id" outlined
                                                          clearable :disabled="isFieldsDisabled">
                                                </v-select>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="hotel_name" type="text" class="custom_field"
                                                              v-model="item.hotel_name"
                                                              :label="$t('general.hotel_name')"
                                                              outlined clearable :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-menu v-model="travel_from_menu" :close-on-content-click="false"
                                                        max-width="290">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field name="travel_from" type="text"
                                                                      class="custom_field"
                                                                      :value="$Helper.methods.formatDate(item.travel_from)"
                                                                      :label="$t('general.travel_from')" outlined
                                                                      clearable
                                                                      readonly :disabled="isFieldsDisabled" v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="item.travel_from" :locale="locale" no-title
                                                                   @change="travel_from_menu = false">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-menu v-model="travel_to_menu" :close-on-content-click="false"
                                                        max-width="290">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field name="travel_to" type="text" class="custom_field"
                                                                      :value="$Helper.methods.formatDate(item.travel_to)"
                                                                      :label="$t('general.travel_to')" outlined
                                                                      clearable
                                                                      readonly :disabled="isFieldsDisabled" v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="item.travel_to" :locale="locale" no-title
                                                                   @change="travel_to_menu = false">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="area" type="text" class="custom_field"
                                                              v-model="item.area" :label="$t('incidents.area')" outlined
                                                              clearable :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <!--Cost info-->
                                        <v-row dense>
                                            <v-col cols="12">
                                                <div class="title mb-4">{{$t('general.cost_info')}}</div>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="deductible" type="number" min="0"
                                                              class="custom_field"
                                                              v-model="item.deductible"
                                                              :label="$t('general.deductible')"
                                                              outlined clearable prefix="€"
                                                              :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                            <v-col v-if="isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="cash" type="number" min="0" class="custom_field"
                                                              v-model="item.cash" :label="$t('general.cash')" outlined
                                                              clearable prefix="€" :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row dense v-if="canManageCost">
                                            <v-col v-if="!isCash" cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="final_cost" type="number" min="0"
                                                              class="custom_field"
                                                              v-model="item.final_cost"
                                                              :label="$t('general.final_cost')"
                                                              outlined clearable prefix="€"
                                                              :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                            <v-col v-if="!isCash" cols="12" sm="6">
                                                <v-textarea name="treatment_cost_notes" class="custom_field"
                                                            v-model="item.treatment_cost_notes"
                                                            :label="$t('general.treatment_cost_notes')" outlined
                                                            clearable
                                                            no-resize :disabled="isFieldsDisabled">
                                                </v-textarea>
                                            </v-col>
                                        </v-row>

                                        <!--Files-->
                                        <v-row dense>
                                            <v-col cols="12" md="6">
                                                <div class="title mb-5">{{$t('general.patient_form')}}</div>
                                                <UploadFiles :postedFiles="patient_form" :max_files="1"
                                                             ref_name="incident_patient_form_files" :list_view="true"
                                                             :filename="`${item.code}_patient_form`"
                                                             :is_disabled="isFieldsDisabled"
                                                             @getFiles="getPatientFormFiles">
                                                </UploadFiles>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <div class="title mb-5">{{$t('general.passport_id')}}</div>
                                                <UploadFiles :postedFiles="passport_id" :max_files="1"
                                                             ref_name="incident_passport_id_files" :list_view="true"
                                                             :filename="`${item.code}_passport_id`"
                                                             :is_disabled="isFieldsDisabled"
                                                             @getFiles="getPassportIdFiles">
                                                </UploadFiles>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <div class="title mb-5">{{$t('general.exit_stamp')}}</div>
                                                <UploadFiles :postedFiles="exit_stamp" :max_files="1"
                                                             ref_name="incident_exit_stamp_files" :list_view="true"
                                                             :filename="`${item.code}_exit_stamp`"
                                                             :is_disabled="isFieldsDisabled"
                                                             @getFiles="getExitStampFiles">
                                                </UploadFiles>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <div class="title mb-5">{{$t('general.policy')}}</div>
                                                <UploadFiles :postedFiles="policy" :max_files="1"
                                                             ref_name="incident_policy_files"
                                                             :list_view="true" :is_disabled="isFieldsDisabled"
                                                             :filename="`${item.code}_policy`"
                                                             @getFiles="getPolicyFiles">
                                                </UploadFiles>
                                            </v-col>
                                            <v-col cols="12">
                                                <div class="title mb-4">{{$t('general.files')}}</div>
                                            </v-col>
                                            <v-col cols="12">
                                                <UploadFiles :postedFiles="files" :is_multiple="true"
                                                             ref_name="incident_other_files"
                                                             :is_disabled="isFieldsDisabled"
                                                             @getFiles="getFiles">
                                                </UploadFiles>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                            </v-tab-item>

                            <!--Doctor tab-->
                            <v-tab-item value="tab_2">
                                <v-card flat>
                                    <v-card-text>
                                        <v-row dense>
                                            <v-col cols="12" md="6">
                                                <v-select name="doctor_id" class="custom_field" v-model="item.doctor_id"
                                                          :items="doctors" :label="$t('incidents.doctor')"
                                                          item-text="name" item-value="id" outlined clearable
                                                          :disabled="isFieldsDisabled">
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col cols="12" md="6">
                                                <v-textarea name="symptoms_description" class="custom_field"
                                                            v-model="item.symptoms_description"
                                                            :label="$t('general.symptoms_description')" outlined
                                                            clearable no-resize :disabled="isFieldsDisabled">
                                                </v-textarea>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-textarea name="description" class="custom_field"
                                                            v-model="item.description"
                                                            :label="$t('incidents.description')" outlined clearable
                                                            no-resize :disabled="isFieldsDisabled">
                                                </v-textarea>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field name="diagnosis_description" type="text"
                                                              class="custom_field" v-model="item.diagnosis_description"
                                                              :label="$t('incidents.diagnosis_description')" outlined
                                                              clearable :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field name="offered_services_place" type="text"
                                                              class="custom_field" v-model="item.offered_services_place"
                                                              :label="$t('incidents.offered_services_place')" outlined
                                                              clearable :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-autocomplete name="icd10s" class="custom_field" v-model="item.icd10s"
                                                                :items="icd10s" :label="$t('general.icd10')"
                                                                item-text="title" item-value="id" outlined clearable
                                                                multiple chips deletable-chips
                                                                :disabled="isFieldsDisabled">
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-textarea name="treatment" class="custom_field"
                                                            v-model="item.treatment" :label="$t('incidents.treatment')"
                                                            outlined clearable no-resize :disabled="isFieldsDisabled">
                                                </v-textarea>
                                            </v-col>
                                            <v-col cols="12">
                                                <div class="d-flex flex-column flex-sm-row doctor_checkboxes">
                                                    <v-checkbox v-model="item.alcohol_use"
                                                                :label="$t('general.alcohol_use')"
                                                                class="custom_checkbox" :disabled="isFieldsDisabled">
                                                    </v-checkbox>
                                                    <v-checkbox v-model="item.pregnancy"
                                                                :label="$t('general.pregnancy')"
                                                                class="custom_checkbox" :disabled="isFieldsDisabled">
                                                    </v-checkbox>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>

                            <!--Superadmin tab-->
                            <v-tab-item v-if="isSuperAdmin" value="tab_3">
                                <v-card flat>
                                    <v-card-text>
                                        <v-row dense>
                                            <v-col cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="number" type="text" class="custom_field"
                                                              v-model="item.number" :label="$t('incidents.number')"
                                                              outlined clearable :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4" lg="3">
                                                <v-select name="status_id" class="custom_field" v-model="item.status_id"
                                                          :items="incident_statuses" :label="$t('general.status')"
                                                          :item-text="`name_${$i18n.locale}`" item-value="id" outlined
                                                          clearable :disabled="isFieldsDisabled">
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4" lg="3">
                                                <v-text-field name="number" type="text" class="custom_field"
                                                              v-model="item.invoice_number"
                                                              :label="$t('incidents.invoice_number')" outlined clearable
                                                              :disabled="isFieldsDisabled">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4" lg="3">
                                                <v-menu v-model="invoice_date_menu" :close-on-content-click="false"
                                                        max-width="290">
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field name="invoice_date" type="text"
                                                                      class="custom_field"
                                                                      :value="$Helper.methods.formatDate(item.invoice_date)"
                                                                      :label="$t('incidents.invoice_date')" outlined
                                                                      clearable readonly :disabled="isFieldsDisabled"
                                                                      v-on="on">
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="item.invoice_date" :locale="locale" no-title
                                                                   @change="invoice_date_menu = false">
                                                    </v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4" lg="3">
                                                <v-select name="bank_account" class="custom_field"
                                                          v-model="item.bank_account_id" :items="bank_accounts"
                                                          :label="$t('bank_accounts.single')" item-text="name"
                                                          item-value="id" outlined clearable
                                                          :disabled="isFieldsDisabled">
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8" lg="6">
                                                <v-select name="company" class="custom_field"
                                                          v-model="item.company_id" :items="companies"
                                                          :label="$t('companies.single')" item-text="friendly_name"
                                                          item-value="id" outlined clearable
                                                          :disabled="isFieldsDisabled">
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>

                        </v-tabs-items>

                    </v-card>
                </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="elevation-1">
                <v-spacer></v-spacer>
                <v-btn color="primary" class="close_button" dark @click="closeDialog">
                    {{$t('general.close')}}
                </v-btn>
                <v-btn v-if="action === 'view' && !isLocked" color="primary" class="update_button" dark
                       @click="updateItem">
                    {{$t('incidents.update')}}
                </v-btn>
                <v-btn v-if="action !== 'view'" class="primary submit_button" dark :loading="is_loading"
                       @click="submitDialog()">
                    {{$t('general.save')}}
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required, email, requiredIf} from 'vuelidate/lib/validators'
    import UploadFiles from './UploadFiles.vue'
    import StatusDialog from './StatusDialog.vue'

    export default {
        props: {
            show: {type: Boolean, default: false},
            item_header: {type: String},
            item_action: {type: String},
            item_id: {type: Number}
        },
        components: {
            UploadFiles, StatusDialog
        },
        mixins: [validationMixin],
        validations() {
            return {
                item: {
                    code: {required},
                    patient_first_name: {required},
                    patient_last_name: {required},
                    date: {required}
                }
            }
        },
        data: self => ({
            show_dialog: self.show,
            action: self.item_action,
            header: self.item_header,
            url: 'incidents',
            tab: null,
            loading_data: false,
            is_loading: false,
            offsetTop: 0,

            // look up data
            genders: [],
            countries: [],
            insurance_companies: [],
            doctors: [],
            icd10s: [],
            incident_statuses: [],
            policy_types: [],
            tour_guides: [],
            bank_accounts: [],
            companies: [],

            // item
            patient_dob_menu: false,
            travel_from_menu: false,
            travel_to_menu: false,
            insurance_start_date_menu: false,
            insurance_end_date_menu: false,
            incident_date_menu: false,
            invoice_date_menu: false,
            item: {
                is_cash: false,

                // required fields
                code: null,
                patient_first_name: null,
                patient_last_name: null,
                date: null,

                // general info
                reference_number: null,
                insurance_reference_number: null,

                // patient info
                patient_dob: null,
                gender_id: null,
                country_id: null,
                passport_number: null,
                address: null,
                phone_number: null,
                email: null,

                // insurance info
                insurance_company_id: null,
                company_id: null,
                policy_number: null,
                policy_type_id: null,
                insurance_start_date: null,
                insurance_end_date: null,
                tour_guide_id: null,
                hotel_name: null,
                travel_from: null,
                travel_to: null,
                area: null,

                // cost info
                deductible: null,
                cash: null,
                treatment_cost_notes: null,

                // doctor info
                doctor_id: null,
                symptoms_description: null,
                description: null,
                diagnosis_description: null,
                offered_services_place: null,
                icd10s: [],
                treatment: null,
                alcohol_use: null,
                pregnancy: null,

                // superadmin info
                final_cost: null,
                number: null,
                invoice_number: null,
                invoice_date: null,
                bank_account_id: null,

                // general fields
                status_id: null,
                is_completed: 0
            },
            item_data: null,

            // files
            patient_form: [],
            passport_id: [],
            exit_stamp: [],
            policy: [],
            files: []
        }),
        computed: {
            locale() {
                return this.$i18n.locale;
            },
            phoneNumberCode() {
                let country = this.countries.find(obj => obj.id === this.item.country_id);
                return country ? country.dial_code : '+30';
            },
            isSuperAdmin() {
                return this.$store.state.user_details.roles.map(obj => obj.name).indexOf('superadmin') > -1;
            },
            isDoctor() {
                return this.$store.state.user_details.roles.map(obj => obj.name).indexOf('doctor') > -1;
            },
            isFieldsDisabled() {
                return this.action === 'view';
            },
            isIncidentCompleted() {

                let fields = this.isCash
                    ? ['code', 'patient_first_name', 'patient_last_name', 'date', 'reference_number', 'country_id']
                    : ['code', 'patient_first_name', 'patient_last_name', 'date', 'patient_dob', 'country_id',
                        'passport_number', 'address', 'phone_number', 'insurance_company_id', 'policy_number',
                        'insurance_start_date', 'insurance_end_date', 'travel_from', 'travel_to', 'doctor_id',
                        'symptoms_description', 'offered_services_place', 'icd10s', 'treatment'];

                // loop fields to find if there is any invalid
                for (let prop of fields) {
                    if (prop === 'icd10s') {
                        if (this.item[prop].length === 0) {
                            return false;
                        }
                    } else {
                        if (this.item[prop] === null) {
                            return false;
                        }
                    }
                }

                // check files arrays
                if (!this.isCash) {
                    return (this.patient_form.length > 0 && this.passport_id.length > 0
                        && this.exit_stamp.length > 0 && this.policy.length > 0);
                }

                return true;
            },
            canManageCost() {
                if (this.isSuperAdmin) {
                    return true;
                }

                return this.$store.state.user_details.medical_office.cost_management === 1;
            },
            isCash() {
                return this.item.is_cash;
            },
            isLocked() {
                if (!this.item_data) {
                    return false;
                }

                if (this.item_data.locked_by_user === 0) {
                    return false;
                }

                return this.item_data.locked_by_user !== this.$store.state.user_details.id;
            },
        },
        async mounted() {
            this.loading_data = true;

            // get genders
            let genders = await this.$store.dispatch('apiRequest', {method: 'get', url: 'settings/genders'});
            this.genders = genders.data;

            // get countries
            let countries = await this.$store.dispatch('apiRequest', {method: 'get', url: 'settings/countries'});
            this.countries = this.$Helper.methods.sortArrayOfObjects({
                array: countries.data, property: `name_${this.locale}`
            });

            // get insurance companies
            let insurance_companies = await this.$store.dispatch('apiRequest', {
                method: 'get', url: 'insurance-companies'
            });
            this.insurance_companies = this.$Helper.methods.sortArrayOfObjects({
                array: insurance_companies.data, property: `name_${this.locale}`
            });

            // get tour guides
            let tour_guides = await this.$store.dispatch('apiRequest', {
                method: 'get', url: 'tour-guides'
            });
            this.tour_guides = this.$Helper.methods.sortArrayOfObjects({
                array: tour_guides.data, property: `name_${this.locale}`
            });

            // get countries
            let doctors = await this.$store.dispatch('apiRequest', {method: 'get', url: 'users/doctors'});
            this.doctors = doctors.data;

            if (this.isDoctor) {
                this.item.doctor_id = this.$store.state.user_details.id;
            }

            // get incident statuses
            let icd10s = await this.$store.dispatch('apiRequest', {method: 'get', url: 'settings/icd10s'});
            this.icd10s = this.$Helper.methods.sortArrayOfObjects({
                array: icd10s.data.filter(obj => obj.title = obj.code + ' - ' + obj[`name_${this.locale}`]),
                property: 'title'
            });

            // get incident statuses
            let incident_statuses = await this.$store.dispatch('apiRequest', {
                method: 'get', url: 'settings/incident-statuses'
            });
            this.incident_statuses = incident_statuses.data;

            // get policy types
            let policy_types = await this.$store.dispatch('apiRequest', {
                method: 'get', url: 'settings/policy-types'
            });
            this.policy_types = policy_types.data;

            // get bank accounts
            let bank_accounts = await this.$store.dispatch('apiRequest', {method: 'get', url: 'bank-accounts'});
            this.bank_accounts = bank_accounts.data;

            // get companies
            let companies = await this.$store.dispatch('apiRequest', {method: 'get', url: 'companies'});
            this.companies = companies.data;

            this.loading_data = false;
        },
        watch: {
            async show(val) {
                this.show_dialog = val;

                if (val) {
                    this.action = this.item_action;

                    // create
                    if (this.action === 'create') {

                        // get incident code
                        let response = await this.$store.dispatch('apiRequest', {method: 'get', url: 'incidents/code'});
                        this.item.code = response.data.code;

                        setTimeout(() => {
                            this.item.status_id = 1;
                            this.item.date = new Date().toISOString().substr(0, 10);
                        }, 300);

                    } else if (['view', 'update'].indexOf(this.action) > -1) {

                        let response = await this.$store.dispatch('apiRequest', {
                            method: 'get', url: `${this.url}/${this.item_id}`,
                            params: {
                                action: 'manage_incident',
                                is_update: this.action === 'update'
                            }
                        });

                        // successful response
                        if (!response.response) {

                            // if in update incident page lock item
                            if (this.action === 'update') {
                                this.lockItem(this.$store.state.user_details.id);
                            }

                            this.item_data = response.data;

                            // set first is_cash prop because when changes clears secretary tab
                            this.item.is_cash = response.data.is_cash;

                            // set item data
                            this.$nextTick().then(() => {

                                // set form data
                                for (let prop in this.item) {
                                    if (prop !== 'is_cash') {
                                        this.item[prop] = (prop === 'icd10s') ? response.data[prop].map(obj => obj.id)
                                            : response.data[prop];
                                    }
                                }

                                // if user has files
                                if (response.data.files.length > 0) {
                                    response.data.files.forEach(async file => {

                                        // get file from API
                                        let new_file = await this.$store.dispatch('getAPIFile', {
                                            url: `${this.url}/file/${file.id}`, file: file
                                        });

                                        // add it to files array
                                        if (new_file) {
                                            let array_name = new_file.name.includes('patient_form') ? 'patient_form'
                                                : new_file.name.includes('passport_id') ? 'passport_id'
                                                    : new_file.name.includes('exit_stamp') ? 'exit_stamp'
                                                        : new_file.name.includes('policy') ? 'policy' : 'files';

                                            this[array_name].push(new_file);
                                        }
                                    });
                                }
                            });

                            // error response
                        } else {
                            this.clearDialog();
                            this.$emit('close-dialog');
                        }
                    }
                }
            },
            item_action(val) {
                this.action = val;
            },
            item_header(val) {
                this.header = val;
            },
            locale(val) {

                // sort genders
                this.genders = this.$Helper.methods.sortArrayOfObjects({
                    array: this.genders, property: `name_${val}`
                });

                // sort countries
                this.countries = this.$Helper.methods.sortArrayOfObjects({
                    array: this.countries, property: `name_${val}`
                });

                // sort insurance companies
                this.insurance_companies = this.$Helper.methods.sortArrayOfObjects({
                    array: this.insurance_companies, property: `name_${val}`
                });
            },
            isCash(val) {
                this.clearSecretaryTab();
            }
        },
        methods: {
            // lock item
            lockItem(locked_by_user) {
                this.$store.dispatch('apiRequest', {
                    method: 'patch', url: `${this.url}/locked/${this.item_id}`,
                    data: {locked_by_user: locked_by_user}, hide_response: true
                });
            },
            // change view to update
            updateItem() {
                this.action = 'update';
                this.header = this.$t('incidents.update');
                this.lockItem(this.$store.state.user_details.id);
            },

            // form field errors
            fieldErrors(prop) {
                const errors = [];
                if (!this.$v.item[prop].$dirty) return errors;

                // add email rules
                if (prop === 'email') {
                    !this.$v.item[prop].email && errors.push(this.$t('validation.email'));
                }

                if (['email'].indexOf(prop) === -1) {
                    !this.$v.item[prop].required && errors.push(this.$t('validation.required'));
                }

                return errors;
            },
            // get all files from upload component
            getFiles(files) {
                this.files = files;
            },
            // get patient form files from upload component
            getPatientFormFiles(files) {
                this.patient_form = files;
            },
            // get passport id files from upload component
            getPassportIdFiles(files) {
                this.passport_id = files;
            },
            // get exit stamp files from upload component
            getExitStampFiles(files) {
                this.exit_stamp = files;
            },
            // get policy files from upload component
            getPolicyFiles(files) {
                this.policy = files;
            },

            // clear dialog
            clearDialog() {
                this.$v.item.$reset();
                this.tab = null;
                this.is_loading = false;

                for (let prop in this.item) {
                    if (prop !== 'code') {
                        this.item[prop] = (prop === 'date') ? new Date().toISOString().substr(0, 10) :
                            (prop === 'icd10s') ? [] : (prop === 'is_completed') ? 0 :
                                (prop === 'is_cash') ? false : null;
                    }
                }

                this.patient_form = [];
                this.passport_id = [];
                this.exit_stamp = [];
                this.policy = [];
                this.files = [];
                this.item_data = null;
            },
            // clear all fields in secretary tab
            clearSecretaryTab() {
                let array = [
                    'insurance_reference_number', 'patient_dob', 'gender_id', 'passport_number', 'address',
                    'phone_number', 'email', 'insurance_company_id', 'policy_number', 'policy_type_id',
                    'insurance_start_date', 'insurance_end_date', 'tour_guide_id', 'hotel_name', 'travel_from',
                    'travel_to', 'area', 'deductible', 'cash', 'treatment_cost_notes'
                ];

                for (let prop of array) {
                    this.item[prop] = null;
                }
            },
            // trigger close dialog event in parent component
            async closeDialog() {
                this.clearDialog();

                // unlock incident
                if (this.action === 'update') {
                    await this.$store.dispatch('apiRequest', {
                        method: 'patch', url: `incidents/locked/${this.item_id}`,
                        data: {locked_by_user: 0}, hide_response: true
                    });
                }

                this.$emit('close-dialog');
            },
            // trigger submit dialog event in parent component
            async submitDialog() {
                this.$v.item.$touch();

                // item is invalid
                if (this.$v.item.$invalid) {
                    this.tab = 'tab_1';
                    let container = document.getElementById('manage_incident_page');

                    if (container) {
                        container.scrollTop = 0;
                    }

                    // item are valid
                } else {

                    this.is_loading = true;
                    this.item.is_completed = this.isIncidentCompleted ? 1 : 0;
                    this.item.is_cash = this.item.is_cash ? 1 : 0;

                    let files = this.files.slice(0);

                    // add patient form to files
                    if (this.patient_form.length > 0) {
                        files.push(this.patient_form[0]);
                    }

                    // add passport id to files
                    if (this.passport_id.length > 0) {
                        files.push(this.passport_id[0]);
                    }

                    // add exit stamp to files
                    if (this.exit_stamp.length > 0) {
                        files.push(this.exit_stamp[0]);
                    }

                    // add policy to files
                    if (this.policy.length > 0) {
                        files.push(this.policy[0]);
                    }

                    let form_data = new FormData();

                    for (let i = 0; i < files.length; i++) {
                        form_data.append(`files[${i}]`, files[i]);
                    }

                    for (let prop in  this.item) {
                        if (['alcohol_use', 'pregnancy'].indexOf(prop) > -1) {
                            form_data.append(prop, this.item[prop] ? 1 : 0);
                        } else {
                            form_data.append(prop, this.item[prop]);
                        }
                    }

                    let response = await this.$store.dispatch('apiRequest', {
                        method: 'post',
                        url: this.action !== 'create' ? `${this.url}/${this.item_id}` : this.url,
                        data: form_data, upload_files: true
                    });

                    this.is_loading = false;

                    // successful response
                    if (!response.response) {
                        this.$emit('submit-dialog');
                        this.clearDialog();
                    }
                }
            }
        }
    }
</script>