<template>
    <v-container fluid id="incidents_page">

        <img id="logo_img" :src="require('../../assets/images/aegean_logo.png')" style="display:none"/>
        <img id="checkbox_img" :src="require('../../assets/images/checkbox_image.png')" style="display:none"/>

        <v-row dense>
            <v-col cols="12" lg="3">
                <v-expansion-panels v-model="search_panel" :disabled="loading">
                    <v-expansion-panel>
                        <v-expansion-panel-header>{{$t('general.search')}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row dense>
                                <v-col v-if="isSuperAdmin" cols="12">
                                    <v-select name="medical_office_id" class="custom_field"
                                              v-model="search.medical_office_id" :items="medical_offices"
                                              :label="$t('medical_offices.single')" :item-text="`name_${$i18n.locale}`"
                                              item-value="id" outlined clearable multiple chips deletable-chips
                                              :disabled="loading">
                                    </v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-select name="insurance_company_id" class="custom_field"
                                              v-model="search.insurance_company_id" :items="insurance_companies"
                                              :label="$t('insurance_companies.single')"
                                              :item-text="`name_${$i18n.locale}`" item-value="id" outlined clearable
                                              multiple chips deletable-chips :disabled="loading">
                                    </v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-menu v-model="date_from_menu" :close-on-content-click="false" max-width="290">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field name="date_from" type="text" class="custom_field"
                                                          :value="$Helper.methods.formatDate(search.date_from)"
                                                          :label="$t('general.date_from')" outlined clearable readonly
                                                          :disabled="loading" v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="search.date_from" :locale="locale" no-title
                                                       @change="date_from_menu = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12">
                                    <v-menu v-model="date_to_menu" :close-on-content-click="false" max-width="290">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field name="date_to" type="text" class="custom_field"
                                                          :value="$Helper.methods.formatDate(search.date_to)"
                                                          :label="$t('general.date_to')" outlined clearable readonly
                                                          :disabled="loading" v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker v-model="search.date_to" :locale="locale" no-title
                                                       @change="date_to_menu = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12">
                                    <v-select name="status_id" class="custom_field" v-model="search.status_id"
                                              :items="incident_statuses" :label="$t('general.status')"
                                              :item-text="`name_${$i18n.locale}`" item-value="id" outlined clearable
                                              multiple chips deletable-chips :disabled="loading">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" align="right">
                                    <v-select name="tour_guide_id" class="custom_field" v-model="search.tour_guide_id"
                                              :items="tour_guides" :label="$t('general.tour_guide')"
                                              :item-text="`name_${$i18n.locale}`" item-value="id" outlined clearable
                                              multiple chips deletable-chips :disabled="loading">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" align="right">
                                    <v-btn color="primary" :disabled="loading" @click="clearSearchForm">
                                        {{$t('general.clear')}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col cols="12" lg="9">
                <!--Datatable Toolbar-->
                <v-toolbar flat color="white" elevation="1" class="custom_datatable_toolbar" height="56">
                    <v-toolbar-title>{{$t('incidents.list')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn v-if="canCreateItem" color="primary" dark class="custom_button mobile_button"
                           @click="openItemDialog('create', 0)" :title="$t('incidents.create')">
                        <v-icon small size="20">add</v-icon>
                    </v-btn>
                    <download-excel :data="items" :fields="excel_fields" :worksheet="$t('incidents.plural')"
                                    :name="`${$t('incidents.plural')}.xls`">
                        <v-btn color="primary" class="custom_button mobile_button ml-2" :title="$t('incidents.export')"
                               :disabled="loading">
                            <v-icon small size="20">cloud_download</v-icon>
                        </v-btn>
                    </download-excel>
                </v-toolbar>

                <!--Datatable-->
                <ResponsiveDataTable :headers="headers" :items="items" :totalItems="total_items" :loading="loading"
                                     :mobileBreakpoint="960" @options-changed="optionsChanged">
                    <!--Buttons-->
                    <template v-slot:buttons="slotProp">
                        <div class="actions">
                            <v-icon v-if="canViewItem" color="primary" :title="$t('general.view')"
                                    @click="openItemDialog('view', slotProp.item.id)">view_list
                            </v-icon>
                            <v-icon v-if="canUpdateItem && !isLocked(slotProp.item)" color="primary"
                                    :title="$t('general.update')" @click="openItemDialog('update', slotProp.item.id)">
                                edit
                            </v-icon>
                            <v-icon v-if="canDeleteItem" color="primary" :title="$t('general.delete')"
                                    @click="showDeleteItemDialog(slotProp)">delete
                            </v-icon>
                            <v-icon v-if="canUpdateItem && isSuperAdmin && isLocked(slotProp.item)" color="primary"
                                    :title="$t('general.unlock')"
                                    @click="showChangeItemLockStatusDialog(slotProp.item)">
                                {{isLocked(slotProp.item) ? 'lock' : 'lock_open'}}
                            </v-icon>
                            <v-icon v-if="canViewItem" color="primary" :title="$t('general.print')"
                                    @click="showPrintDialog(slotProp.item)">print
                            </v-icon>
                        </div>
                    </template>
                </ResponsiveDataTable>
            </v-col>
        </v-row>

        <!--Delete item dialog-->
        <StatusDialog :show="show_delete_item_dialog" :header="$t('incidents.delete')" color="error"
                      :text="$t('incidents.delete_confirmation')" :is_loading="delete_item_dialog_loading"
                      @confirm="deleteItem" @close="closeDeleteItemDialog">
        </StatusDialog>

        <!--Change item locked status dialog-->
        <StatusDialog :show="show_change_item_lock_status_dialog" :header="change_item_lock_status_dialog_header"
                      color="error" :text="change_item_lock_status_dialog_text"
                      :is_loading="change_item_lock_status_dialog_loading" @confirm="changeItemLockStatus"
                      @close="closeChangeItemLockStatusDialog">
        </StatusDialog>

        <!--Print Dialog-->
        <Dialog :show="show_print_dialog" max_width="300px" :header="$t('incidents.print')"
                :is_loading="print_dialog_loading" :submit_button_text="$t('general.print')"
                @close-dialog="closePrintDialog" @submit-dialog="submitPrintDialog">
            <template slot="content">
                <v-row dense class="print_checkboxes">
                    <v-col cols="12">
                        <v-checkbox v-model="patient_info_pdf" class="mx-2" :label="$t('general.patient_info')">
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="medical_report_pdf" class="mx-2" :label="$t('general.medical_report')">
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="cost_info_pdf" class="mx-2" :label="$t('general.cost_info')">
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12" v-if="isSuperAdmin">
                        <v-checkbox v-model="print_invoice" class="mx-2" :label="$t('incidents.print_invoice')">
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-model="attachments_pdf" class="mx-2" :label="$t('general.attachments')">
                        </v-checkbox>
                    </v-col>
                </v-row>
            </template>
        </Dialog>

        <!--Item Dialog-->
        <ManageIncidentDialog :show="show_item_dialog" :item_header="item_dialog_header"
                              :is_loading="item_dialog_loading" :item_action="item_action" :item_id="item_id"
                              @close-dialog="closeItemDialog" @submit-dialog="submitItemDialog">
        </ManageIncidentDialog>

    </v-container>
</template>

<script>
    import ResponsiveDataTable from '../../components/ResponsiveDataTable.vue'
    import StatusDialog from '../../components/StatusDialog.vue'
    import Dialog from '../../components/Dialog.vue'
    import ManageIncidentDialog from '../../components/ManageIncidentDialog.vue'

    import PDFJS from 'pdfjs-dist';
    import JsonExcel from 'vue-json-excel'
    import moment from 'moment';

    let pdfMake = require('pdfmake/build/pdfmake.js');
    let pdfFonts = require('pdfmake/build/vfs_fonts.js');
    let htmlToPdfmake = require("html-to-pdfmake");
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    export default {
        components: {
            ResponsiveDataTable, StatusDialog, Dialog, PDFJS, 'downloadExcel': JsonExcel, ManageIncidentDialog
        },
        data: self => ({
            url: 'incidents',

            // actions permissions
            canViewItem: false,
            canCreateItem: false,
            canUpdateItem: false,
            canDeleteItem: false,

            // search
            search_panel: 0,
            medical_offices: [],
            insurance_companies: [],
            incident_statuses: [],
            tour_guides: [],
            bank_accounts: [],
            date_from_menu: false,
            date_to_menu: false,
            search: {
                medical_office_id: [],
                insurance_company_id: [],
                date_from: null,
                date_to: null,
                status_id: [],
                tour_guide_id: []
            },
            search_filters: {},

            // datatable
            headers: [],
            total_items: null,
            items: [],
            loading: false,
            options: {
                page: null,
                itemsPerPage: null,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: false,
                mustSort: false
            },
            excel_fields: {},

            item_id: null,

            // manage item
            show_item_dialog: false,
            item_dialog_header: null,
            item_dialog_loading: false,
            item_action: null,

            // delete item
            show_delete_item_dialog: false,
            delete_item_dialog_loading: false,

            // change lock status
            show_change_item_lock_status_dialog: false,
            change_item_lock_status_dialog_loading: false,
            change_item_lock_status_dialog_header: null,
            change_item_lock_status_dialog_text: null,

            // print
            show_print_dialog: false,
            print_dialog_loading: false,
            print_item: null,
            patient_info_pdf: true,
            medical_report_pdf: false,
            cost_info_pdf: false,
            print_invoice: false,
            attachments_pdf: false
        }),
        computed: {
            locale() {
                return this.$i18n.locale;
            },
            isSuperAdmin() {
                return this.$store.state.user_details.roles.map(obj => obj.name).indexOf('superadmin') > -1;
            }
        },
        created() {
            this.headers = this.getHeaders();
            this.excel_fields = this.getExcelFields();
        },
        async mounted() {

            this.canCreateItem = await this.$store.dispatch('hasPermission', 'create_incident');
            this.canViewItem = await this.$store.dispatch('hasPermission', 'view_incident');
            this.canUpdateItem = await this.$store.dispatch('hasPermission', 'update_incident');
            this.canDeleteItem = await this.$store.dispatch('hasPermission', 'delete_incident');

            // get medicine offices
            let response = await this.$store.dispatch('apiRequest', {method: 'get', url: 'medical-offices'});

            // sort medicine offices
            this.medical_offices = this.$Helper.methods.sortArrayOfObjects({
                array: (!response.response) ? response.data : [], property: `name_${this.$i18n.locale}`
            });

            // get insurance companies
            let insurance_companies = await this.$store.dispatch('apiRequest', {
                method: 'get', url: 'insurance-companies'
            });

            // sort insurance companies
            this.insurance_companies = this.$Helper.methods.sortArrayOfObjects({
                array: insurance_companies.data, property: `name_${this.locale}`
            });

            // get incident statuses
            let incident_statuses = await this.$store.dispatch('apiRequest', {
                method: 'get', url: 'settings/incident-statuses'
            });
            this.incident_statuses = incident_statuses.data;

            // get tour guides
            let tour_guides = await this.$store.dispatch('apiRequest', {
                method: 'get', url: 'tour-guides'
            });
            this.tour_guides = this.$Helper.methods.sortArrayOfObjects({
                array: tour_guides.data, property: `name_${this.locale}`
            });

            this.setDefaultSearchCriteria();
        },
        watch: {
            locale(val) {
                this.headers = this.getHeaders();
                this.excel_fields = this.getExcelFields();
            },
            search: {
                handler(val) {
                    let filters = this.$Helper.methods.createSearchFilters(Object.assign({}, val));

                    // if filters did change get data
                    if (JSON.stringify(this.filters) !== JSON.stringify(filters)) {
                        this.search_filters = filters;

                        setTimeout(() => {
                            this.getData();
                        }, 100);
                    }
                },
                deep: true
            },
            patient_info_pdf(val) {
                if (val) {
                    this.attachments_pdf = false;
                }
            },
            medical_report_pdf(val) {
                if (val) {
                    this.attachments_pdf = false;
                }
            },
            cost_info_pdf(val) {
                if (val) {
                    this.attachments_pdf = false;
                }
            },
            print_invoice(val) {
                if (val) {
                    this.attachments_pdf = false;
                }
            },
            attachments_pdf(val) {
                if (val) {
                    this.patient_info_pdf = false;
                    this.medical_report_pdf = false;
                    this.cost_info_pdf = false;
                    this.print_invoice = false;
                }
            }
        },
        methods: {
            // return headers array
            getHeaders() {
                return [
                    {text: `${this.$t('incidents.code')}`, value: 'code', sortable: true},
                    {text: `${this.$t('general.status')}`, value: 'status_name', sortable: true},
                    {text: `${this.$t('general.reference_number')}`, value: 'reference_number', sortable: true},
                    {text: `${this.$t('general.patient_name')}`, value: 'patient_name', sortable: true},
                    {text: `${this.$t('insurance_companies.single')}`, value: 'insurance_company_name', sortable: true},
                    {text: `${this.$t('incidents.date')}`, value: 'date', sortable: true},
                    {text: `${this.$t('datatable.actions')}`, value: 'actions', align: 'right', sortable: false}
                ]
            },
            // determine if incident is locked
            isLocked(item) {
                return item.locked_by_user !== 0 && item.locked_by_user !== this.$store.state.user_details.id;
            },
            // get excel export fields
            getExcelFields() {
                let obj = {};
                obj[`${this.$t('incidents.code')}`] = 'code';
                obj[`${this.$t('general.patient_name')}`] = 'patient_name';
                obj[`${this.$t('general.dob')}`] = 'patient_dob';
                obj[`${this.$t('insurance_companies.single')}`] = 'insurance_company_name';
                obj[`${this.$t('incidents.date')}`] = 'date';
                obj[`${this.$t('general.reference_number')}`] = 'reference_number';
                obj[`${this.$t('general.insurance_reference_number')}`] = 'insurance_reference_number';
                return obj;
            },

            // search
            // set default values for search form
            setDefaultSearchCriteria() {

                // set default medical office
                if (!this.isSuperAdmin) {
                    this.search.medical_office_id.push(this.$store.state.user_details.medical_office_id);
                }

                // set default search dates
                let date = new Date();
                this.search.date_from = `${date.getFullYear()}-${date.getMonth() + 1}-01`;
                this.search.date_to = date.toISOString().substring(0, 10);
            },
            // clear search form
            clearSearchForm() {
                for (let prop in this.search) {
                    this.search[prop] = (['medical_office_id', 'insurance_company_id', 'status_id', 'tour_guide_id']
                        .indexOf(prop) > -1) ? [] : null;
                }

                this.setDefaultSearchCriteria();
            },

            // datatable
            // apply changed options on datatable
            async optionsChanged(options) {
                let options_changed = false;
                this.loading = true;

                // check if options changed
                for (let prop in this.options) {
                    if (this.options[prop] !== options[prop]) {
                        options_changed = true;
                        break;
                    }
                }

                if (options_changed) {
                    this.options = Object.assign({}, options);
                    await this.getData();
                }
            },
            // get data from API
            async getData() {
                if (!(Object.keys(this.search_filters).length === 0 && this.search_filters.constructor === Object)) {
                    this.loading = true;

                    let response = await this.$store.dispatch('apiRequest', {
                        method: 'get', url: this.url, params: {
                            options: this.options, search_filters: this.search_filters
                        }
                    });

                    this.loading = false;

                    // successful response
                    if (!response.response) {
                        this.total_items = response.data.total_items;
                        this.items = response.data.items;
                    }
                }
            },

            // Manage Item
            openItemDialog(action, id) {
                this.item_dialog_header = (action === 'create') ? this.$t('incidents.create')
                    : (action === 'update') ? this.$t('incidents.update') : this.$t('incidents.view');
                this.item_id = id;
                this.item_action = action;
                this.show_item_dialog = true;
            },
            // clear item dialog related data
            clearItemDialog() {
                this.item_dialog_header = null;
                this.item_id = null;
                this.item_action = null;
            },
            // close item dialog
            closeItemDialog() {
                this.show_item_dialog = false;
                this.clearItemDialog();
            },
            // submit item dialog
            submitItemDialog() {
                this.show_item_dialog = false;
                this.clearItemDialog();
                this.getData();
            },

            // Delete item
            // show delete item dialog
            showDeleteItemDialog(prop) {
                this.show_delete_item_dialog = true;
                this.item_id = prop.item.id;
            },
            // close delete item dialog
            closeDeleteItemDialog() {
                this.show_delete_item_dialog = false;
                this.item_id = null;
            },
            // delete item
            async deleteItem() {
                this.delete_item_dialog_loading = true;

                let response = await this.$store.dispatch('apiRequest', {
                    method: 'delete', url: `${this.url}/${this.item_id}`
                });

                this.delete_item_dialog_loading = false;

                // successful response
                if (!response.response) {

                    // close dialog
                    this.closeDeleteItemDialog();

                    // get items
                    await this.getData();
                }
            },

            // Lock / Unlock item
            // show change item lock status dialog
            showChangeItemLockStatusDialog(item) {
                if (item.locked_by_user !== 0) {
                    this.show_change_item_lock_status_dialog = true;
                    this.change_item_lock_status_dialog_header = this.$t('incidents.unlock');
                    this.change_item_lock_status_dialog_text = this.$t('incidents.unlock_confirmation');
                    this.item_id = item.id;
                }
            },
            // close change item lock status dialog
            closeChangeItemLockStatusDialog() {
                this.show_change_item_lock_status_dialog = false;
                this.change_item_lock_status_dialog_loading = false;
                this.change_item_lock_status_dialog_header = null;
                this.change_item_lock_status_dialog_text = null;
                this.item_id = null;
            },
            // change item lock status
            async changeItemLockStatus() {
                this.change_item_lock_status_dialog_loading = true;

                let response = await this.$store.dispatch('apiRequest', {
                    method: 'patch', url: `${this.url}/locked/${this.item_id}`,
                    data: {locked_by_user: 0}
                });

                this.change_item_lock_status_dialog_loading = false;

                // successful response
                if (!response.response) {

                    // close dialog
                    this.closeChangeItemLockStatusDialog();

                    // get items
                    await this.getData();
                }
            },

            // Print
            // show print pdf dialog
            showPrintDialog(item) {
                this.show_print_dialog = true;
                this.print_item = item;
            },
            // close print pdf dialog
            closePrintDialog() {
                this.show_print_dialog = false;
                this.print_dialog_loading = false;
                this.print_item = null;
                this.patient_info_pdf = true;
                this.medical_report_pdf = false;
                this.cost_info_pdf = false;
                this.print_invoice = false;
            },

            // create canvas for the given image id
            createImageCanvas(image_id) {

                let image = document.getElementById(image_id);
                let canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                canvas.getContext('2d').drawImage(image, 0, 0);
                canvas.toDataURL('image/png');

                return canvas;
            },
            // create references numbers row
            createReferencesRow(incident, show_insurance_no) {

                let body = [{
                    alignment: 'justify',
                    columns: [
                        {width: 80, text: `${this.$t('general.reference_number')}:`, style: 'field_left'},
                        {width: '*', text: incident.reference_number}
                    ]
                }];

                if (show_insurance_no) {
                    body.push({
                        alignment: 'justify',
                        columns: [
                            {
                                width: 132, text: `${this.$t('general.insurance_reference_number')}:`,
                                style: 'field_left'
                            },
                            {width: '*', text: incident.insurance_reference_number}
                        ]
                    });
                }

                return {
                    table: {
                        widths: show_insurance_no ? ['50%', '50%'] : ['100%'],
                        body: [body]
                    },
                    layout: 'noBorders',
                    margin: [0, 0, 0, 10]
                }
            },
            // create divider header
            createHeaderLine(text, font_size = 14) {
                return {
                    table: {
                        widths: ['100%'],
                        headerRows: 1,
                        body: [[{text: text.toUpperCase(), style: 'header', fontSize: font_size}], ['']]
                    },
                    layout: 'headerLineOnly',
                    margin: [0, 0, 0, 15]
                }
            },
            // create incident patient row
            createPatientNameRow(incident) {
                return {
                    alignment: 'justify',
                    columns: [
                        {width: 36, text: `${this.$t('general.name')}:`, style: 'field_left'},
                        {width: '*', text: `${incident.patient_last_name} ${incident.patient_first_name}`}
                    ],
                    margin: [0, 0, 0, 10]
                }
            },
            // create incident patient row
            createPatientPersonalInfoRow(incident) {
                return {
                    table: {
                        widths: ['33,33%', '33,33%', '33,33%'],
                        body: [
                            [
                                {
                                    alignment: 'justify',
                                    columns: [
                                        {width: 70, text: `${this.$t('general.dob')}:`, style: 'field_left'},
                                        {width: '*', text: this.$Helper.methods.formatDate(incident.patient_dob)}
                                    ]
                                },
                                {
                                    alignment: 'justify',
                                    columns: [
                                        {width: 23, text: `${this.$t('general.gender')}:`, style: 'field_left'},
                                        {
                                            width: '*',
                                            text: incident.gender ? `${incident.gender[`name_${this.locale}`]}` : ''
                                        }
                                    ]
                                },
                                {
                                    alignment: 'justify',
                                    columns: [
                                        {width: 46, text: `${this.$t('general.country')}:`, style: 'field_left'},
                                        {
                                            width: '*',
                                            text: incident.country ? `${incident.country[`name_${this.locale}`]}` : ''
                                        }
                                    ]
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders',
                    margin: [0, 0, 0, 10]
                }
            },
            // generate patient info pdf
            generatePatientInfo(incident, pageBreak = false) {

                let content = [
                    {
                        alignment: 'right',
                        columns: [
                            {text: `${this.$t('incidents.code')}: ${incident.code}`, style: 'code'}
                        ],
                        margin: [0, 0, 0, 15]
                    },
                    this.createReferencesRow(incident, true),
                    this.createHeaderLine(this.$t('general.patient_info')),
                    this.createPatientNameRow(incident),
                    this.createPatientPersonalInfoRow(incident),
                    {
                        alignment: 'justify',
                        columns: [
                            {width: 96, text: `${this.$t('general.passport_number')}:`, style: 'field_left'},
                            {width: '*', text: incident.passport_number}
                        ],
                        margin: [0, 0, 0, 10]
                    },
                    {
                        alignment: 'justify',
                        columns: [
                            {width: 48, text: `${this.$t('general.address')}:`, style: 'field_left'},
                            {width: '*', text: incident.address}
                        ],
                        margin: [0, 0, 0, 10]
                    },
                    {
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 60, text: `${this.$t('general.phone_number')}:`,
                                                style: 'field_left'
                                            },
                                            {width: '*', text: incident.phone_number}
                                        ]
                                    },
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {width: 34, text: `${this.$t('general.email')}:`, style: 'field_left'},
                                            {width: '*', text: incident.email}
                                        ]
                                    }
                                ]
                            ]
                        },
                        layout: 'noBorders',
                        margin: [0, 0, 0, 10]
                    },
                    this.createHeaderLine(this.$t('general.insurance_info')),
                    {
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 110, text: `${this.$t('insurance_companies.single')}:`,
                                                style: 'field_left'
                                            },
                                            {
                                                width: '*',
                                                text: incident.insurance_company ? `${incident.insurance_company[`name_${this.locale}`]}` : ''
                                            }
                                        ]
                                    },
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 58, text: `${this.$t('general.policy_number')}:`,
                                                style: 'field_left'
                                            },
                                            {width: '*', text: incident.policy_number}
                                        ]
                                    }
                                ]
                            ]
                        },
                        layout: 'noBorders',
                        margin: [0, 0, 0, 10]
                    },
                    {
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 111, text: `${this.$t('incidents.insurance_start_date')}:`,
                                                style: 'field_left'
                                            },
                                            {
                                                width: '*',
                                                text: this.$Helper.methods.formatDate(incident.insurance_start_date)
                                            }
                                        ]
                                    },
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 106, text: `${this.$t('incidents.insurance_end_date')}:`,
                                                style: 'field_left'
                                            },
                                            {
                                                width: '*',
                                                text: this.$Helper.methods.formatDate(incident.insurance_end_date)
                                            }
                                        ]
                                    }
                                ]
                            ]
                        },
                        layout: 'noBorders',
                        margin: [0, 0, 0, 10]
                    },
                    {
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 60, text: `${this.$t('general.tour_guide')}:`,
                                                style: 'field_left'
                                            },
                                            {
                                                width: '*',
                                                text: incident.tour_guide ? `${incident.tour_guide[`name_${this.locale}`]}` : ''
                                            }
                                        ]
                                    },
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 66, text: `${this.$t('general.hotel_name')}:`,
                                                style: 'field_left'
                                            },
                                            {width: '*', text: incident.hotel_name}
                                        ]
                                    }
                                ]
                            ]
                        },
                        layout: 'noBorders',
                        margin: [0, 0, 0, 10]
                    },
                    {
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 65, text: `${this.$t('general.travel_from')}:`,
                                                style: 'field_left'
                                            },
                                            {
                                                width: '*',
                                                text: this.$Helper.methods.formatDate(incident.travel_from)
                                            }
                                        ]
                                    },
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 50, text: `${this.$t('general.travel_to')}:`,
                                                style: 'field_left'
                                            },
                                            {
                                                width: '*',
                                                text: this.$Helper.methods.formatDate(incident.travel_to)
                                            }
                                        ]
                                    }
                                ]
                            ]
                        },
                        layout: 'noBorders',
                        margin: [0, 0, 0, 10]
                    },
                    {
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 74, text: `${this.$t('incidents.date')}:`,
                                                style: 'field_left'
                                            },
                                            {width: '*', text: this.$Helper.methods.formatDate(incident.date)}
                                        ]
                                    },
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 73, text: `${this.$t('incidents.area')}:`,
                                                style: 'field_left'
                                            },
                                            {width: '*', text: incident.area}
                                        ]
                                    }
                                ]
                            ]
                        },
                        layout: 'noBorders',
                        margin: [0, 0, 0, 70]
                    }
                ];

                // add new page
                if (pageBreak) {
                    content[content.length - 1].pageBreak = 'after';
                }

                return content;
            },
            // generate medical report pdf
            generateMedicalReport(incident) {
                return new Promise(async (resolve, reject) => {

                    let icd10s_string = '';

                    // add all icd10 to one string
                    if (incident.icd10s) {
                        incident.icd10s.forEach((obj, key) => {
                            let comma = (key < incident.icd10s.length - 1) ? ', ' : '';
                            icd10s_string += `${obj.code} ${obj[`name_${this.locale}`]}${comma}`;
                        });
                    }

                    // icd10s row
                    let icd10s = {
                        alignment: 'justify',
                        columns: [{width: '*', text: icd10s_string}],
                        margin: [0, 0, 0, 10]
                    };

                    // icd diagnosis row
                    let icd_diagnosis = !incident.diagnosis_description ? '' :
                        {
                            alignment: 'justify',
                            columns: [{width: '*', text: incident.diagnosis_description}],
                            margin: [0, 0, 0, 10]
                        };

                    let content = [
                        this.createReferencesRow(incident, false),
                        this.createHeaderLine(this.$t('general.medical_report')),
                        this.createPatientNameRow(incident),
                        this.createPatientPersonalInfoRow(incident),
                        {
                            alignment: 'justify',
                            columns: [
                                {width: 74, text: `${this.$t('incidents.date')}:`, style: 'field_left'},
                                {width: '*', text: this.$Helper.methods.formatDate(incident.date)}
                            ],
                            margin: [0, 0, 0, 10]
                        },
                        {
                            alignment: 'justify',
                            columns: [
                                {
                                    width: 125,
                                    text: `${this.$t('general.symptoms_description')}:`,
                                    style: 'field_left'
                                },
                            ],
                            margin: [0, 0, 0, 10]
                        },
                        {
                            alignment: 'justify',
                            columns: [
                                {width: '*', text: incident.symptoms_description}
                            ],
                            margin: [0, 0, 0, 10]
                        },
                        {
                            alignment: 'justify',
                            columns: [
                                {width: 150, text: `${this.$t('incidents.description')}:`, style: 'field_left'},
                            ],
                            margin: [0, 0, 0, 10]
                        },
                        {
                            alignment: 'justify',
                            columns: [
                                {width: '*', text: incident.description}
                            ],
                            margin: [0, 0, 0, 10]
                        },
                        {
                            alignment: 'justify',
                            columns: [
                                {width: 120, text: `${this.$t('general.icd10')}:`, style: 'field_left'},
                            ],
                            margin: [0, 0, 0, 10]
                        },
                        icd10s,
                        icd_diagnosis,
                        {
                            alignment: 'justify',
                            columns: [
                                {
                                    width: 205, text: `${this.$t('incidents.offered_services_place')}:`,
                                    style: 'field_left'
                                },
                                {width: '*', text: incident.offered_services_place}
                            ],
                            margin: [0, 0, 0, 10]
                        },
                        {
                            alignment: 'justify',
                            columns: [
                                {
                                    width: 169,
                                    text: `${this.$t('incidents.treatment')}:`,
                                    style: 'field_left'
                                },
                            ],
                            margin: [0, 0, 0, 10]
                        },
                        {
                            alignment: 'justify',
                            columns: [
                                {width: '*', text: incident.treatment}
                            ],
                            margin: [0, 0, 0, 10]
                        }
                    ];

                    if (incident.doctor) {

                        // if doctor has stamp image
                        if (incident.doctor.files.length > 0) {

                            // get file from API
                            let new_file = await this.$store.dispatch('getAPIFile', {
                                url: `users/file/${incident.doctor.files[0].id}`, file: incident.doctor.files[0]
                            });

                            let reader = new FileReader();

                            // parse image to data url format
                            reader.addEventListener('load', () => {
                                resolve({image: reader.result, text: null, content: content});
                            }, false);

                            reader.readAsDataURL(new_file);

                            // else get doctor text
                        } else {
                            resolve({image: null, text: incident.doctor.doctor_text, content: content});
                        }

                    } else {
                        resolve({image: null, text: null, content: content});
                    }
                });
            },
            // generate cost info pdf
            generateCostInfo(incident, pageBreak = false) {

                let content = [
                    this.createReferencesRow(incident, false),
                    this.createHeaderLine(this.$t('general.cost_info')),
                    this.createPatientNameRow(incident),
                    this.createPatientPersonalInfoRow(incident),
                    {
                        alignment: 'justify',
                        columns: [
                            {width: 56, text: `${this.$t('general.final_cost')}:`, style: 'field_left'},
                            {width: '*', text: `${incident.final_cost} €`}
                        ],
                        margin: [0, 0, 0, 10]
                    },
                    {
                        alignment: 'justify',
                        columns: [
                            {width: 119, text: `${this.$t('general.treatment_cost_notes')}:`, style: 'field_left'},
                        ],
                        margin: [0, 0, 0, 10]
                    },
                    {
                        alignment: 'justify',
                        columns: [
                            {width: '*', text: incident.treatment_cost_notes}
                        ],
                        margin: [0, 0, 0, 10]
                    },
                ];

                // add new page
                if (pageBreak) {
                    content[content.length - 1].pageBreak = 'after';
                }

                return content;
            },
            // generate invoice info pdf
            generateInvoiceInfo(incident, pageBreak = false) {

                // calculate payment date
                let payment_date = moment(incident.invoice_date);
                payment_date.add(15, 'days');

                let icd10s_string = '';

                // add all icd10 to one string
                if (incident.icd10s) {
                    incident.icd10s.forEach((obj, key) => {
                        let comma = (key < incident.icd10s.length - 1) ? ', ' : '';
                        icd10s_string += `${obj.code}${comma}`;
                    });
                }

                // icd10s row
                let icd10s = !incident.icd10s ? '' : {
                    alignment: 'justify',
                    columns: [
                        {
                            width: 76, text: `${this.$t('pdf.icd10_codes')}:`,
                            style: 'field_left'
                        },
                        {width: '*', text: icd10s_string}
                    ],
                    margin: [0, 0, 0, 10]
                };

                let bank_account = '';

                // table with bank account details
                if (incident.bank_account) {
                    bank_account = {
                        table: {
                            widths: ['100%'],
                            body: [
                                [{
                                    text: `${incident.bank_account.name}, ${this.$t('general.swift_bic')}: ${incident.bank_account.swift}`,
                                    alignment: 'center', bold: true
                                }],
                                [{
                                    text: `${incident.bank_account.address} ${this.$t('general.tel')}: ${incident.bank_account.phone_number}`,
                                    alignment: 'center', bold: true
                                }],
                                [{
                                    text: `${this.$t('general.iban').toUpperCase()} : ${incident.bank_account.iban}`,
                                    alignment: 'center', bold: true
                                }],
                                [{
                                    text: `${this.$t('pdf.aegean_favour')} ${incident.bank_account.beneficiary_name}`,
                                    alignment: 'center', bold: true
                                }]
                            ]
                        },
                        layout: {
                            hLineColor: function (i, node) {
                                return 'white';
                            },
                            vLineColor: function (i, node) {
                                return 'white';
                            },
                            paddingLeft: function (i, node) {
                                return 0;
                            },
                            paddingRight: function (i, node) {
                                return 0;
                            },
                            paddingTop: function (i, node) {
                                return 0;
                            },
                            paddingBottom: function (i, node) {
                                return 0;
                            }
                        },
                        margin: [0, 0, 0, 10]
                    }
                }

                let content = [
                    {
                        alignment: 'right',
                        columns: [
                            {
                                width: '*', text: `${this.$t('incidents.invoice_date')}: `, style: 'code'
                            },
                            {
                                width: '75', text: incident.invoice_date
                                ? `${this.$Helper.methods.formatDate(incident.invoice_date)}` : '',
                                style: 'code', alignment: 'left'
                            }
                        ],
                        margin: [0, 0, 0, 5]
                    },
                    {
                        alignment: 'right',
                        columns: [
                            {
                                width: '*', text: `${this.$t('incidents.invoice_number')}: `, style: 'code'
                            },
                            {
                                width: '75', text: incident.invoice_number ? `${incident.invoice_number}` : '',
                                style: 'code', alignment: 'left'
                            },
                        ],
                        margin: [0, 0, 0, 15]
                    },
                    this.createHeaderLine(this.$t('general.invoice'), 20),
                    {
                        table: {
                            widths: [20, '*'],
                            body: [
                                [
                                    {text: `${this.$t('general.to')}:`, style: 'field_left'},
                                    {
                                        text: incident.insurance_company ?
                                            `${incident.insurance_company[`name_${this.locale}`]}` : ''
                                    }
                                ],
                                [
                                    {},
                                    {
                                        text: incident.insurance_company ?
                                            `${incident.insurance_company.address} ${incident.insurance_company.vat}` : ''
                                    }
                                ],
                                [
                                    {},
                                    {text: `For clinical examination and treatment of the patient mentioned below:`}
                                ]
                            ]
                        },
                        layout: 'noBorders',
                        margin: [0, 0, 0, 10]
                    },
                    {
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 75, text: `${this.$t('general.patient_name')}:`,
                                                style: 'field_left'
                                            },
                                            {
                                                width: '*',
                                                text: `${incident.patient_last_name} ${incident.patient_first_name}`
                                            }
                                        ],
                                    },
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 132, text: `${this.$t('general.insurance_reference_number')}:`,
                                                style: 'field_left'
                                            },
                                            {width: '*', text: incident.insurance_reference_number}
                                        ]
                                    },
                                ],
                            ]
                        },
                        layout: 'noBorders',
                        margin: [0, 0, 0, 10]
                    },
                    {
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {width: 70, text: `${this.$t('general.dob')}:`, style: 'field_left'},
                                            {width: '*', text: this.$Helper.methods.formatDate(incident.patient_dob)}
                                        ],
                                    },
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 80, text: `${this.$t('general.reference_number')}:`,
                                                style: 'field_left'
                                            },
                                            {width: '*', text: incident.reference_number}
                                        ]
                                    },
                                ]
                            ]
                        },
                        layout: 'noBorders',
                        margin: [0, 0, 0, 10]

                    },
                    {
                        table: {
                            widths: ['50%', '50%'],
                            body: [
                                [
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {width: 74, text: `${this.$t('incidents.date')}:`, style: 'field_left'},
                                            {
                                                width: '*',
                                                text: incident.date ? this.$Helper.methods.formatDate(incident.date) : ''
                                            }
                                        ],
                                    },
                                    {
                                        alignment: 'justify',
                                        columns: [
                                            {
                                                width: 58, text: `${this.$t('general.policy_number')}:`,
                                                style: 'field_left'
                                            },
                                            {width: '*', text: incident.policy_number}
                                        ]
                                    },
                                ],
                            ]
                        },
                        layout: 'noBorders',
                        margin: [0, 0, 0, 10]
                    },
                    icd10s,
                    {
                        alignment: 'justify',
                        columns: [
                            {
                                width: 205, text: `${this.$t('incidents.offered_services_place')}:`, style: 'field_left'
                            },
                        ],
                        margin: [0, 0, 0, 10]
                    },
                    {
                        alignment: 'justify',
                        columns: [
                            {width: '*', text: incident.offered_services_place}
                        ],
                        margin: [0, 0, 0, 10]
                    },
                    {
                        table: {
                            widths: ['100%'],
                            body: [
                                [{
                                    alignment: 'justify',
                                    columns: [
                                        {
                                            width: 175,
                                            text: `${this.$t('pdf.total_amount_for_payment').toUpperCase()}:`,
                                            style: 'field_left'
                                        },
                                        {
                                            width: '*',
                                            text: !incident.final_cost ? ''
                                                : `${incident.final_cost} € (${this.$Helper.methods.numberToWordsWithDecimal(incident.final_cost)})`
                                        }
                                    ],
                                }],
                                [{
                                    alignment: 'justify',
                                    columns: [
                                        {
                                            width: 108,
                                            text: `${this.$t('pdf.date_of_payment').toUpperCase()}:`,
                                            style: 'field_left'
                                        },
                                        {
                                            width: '*',
                                            text: !incident.invoice_date ? '' : payment_date.format('DD/MM/YYYY')
                                        }
                                    ],
                                }],
                                [
                                    {text: this.$t('pdf.aegean'), alignment: 'right', margin: [0, 0, 0, 40]}
                                ],
                            ]
                        },
                        layout: {
                            hLineColor: function (i, node) {
                                return (i === 0 || i === node.table.body.length) ? 'black' : 'white';
                            },
                            vLineColor: function (i, node) {
                                return (i === 0 || i === node.table.widths.length) ? 'black' : 'white';
                            },
                            paddingLeft: function (i, node) {
                                return 25;
                            },
                            paddingRight: function (i, node) {
                                return 25;
                            },
                            paddingTop: function (i, node) {
                                return 25;
                            },
                            paddingBottom: function (i, node) {
                                return 0;
                            }
                        },
                        margin: [0, 0, 0, 10]
                    },
                    {text: this.$t('pdf.inform'), bold: true, fontSize: 10, margin: [0, 0, 0, 20]},
                    {text: `${this.$t('pdf.payment_details')}`, style: 'field_left', margin: [0, 0, 0, 10]},
                    bank_account,
                    {text: this.$t('pdf.invoice_valid'), bold: true, fontSize: 10, alignment: 'center'},
                ];

                // add new page
                if (pageBreak) {
                    content[content.length - 1].pageBreak = 'after';
                }

                return content;
            },
            // get file and return base64 img
            async readImage(file) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();

                    reader.addEventListener('load', () => {
                        resolve(reader.result);
                    });

                    reader.readAsDataURL(file);
                });
            },
            // get file and return pdf pages as images
            async readPDF(file) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();

                    reader.addEventListener('load', async () => {

                        let pdf_pages = [];
                        const pdf = await PDFJS.getDocument(reader.result).promise;

                        for (let i = 1; i <= pdf.numPages; i++) {

                            const page = await pdf.getPage(i);
                            const viewport = page.getViewport({scale: 6.0});

                            // Prepare canvas using PDF page dimensions
                            let canvas = document.createElement('canvas');
                            let context = canvas.getContext('2d');
                            canvas.height = viewport.height;
                            canvas.width = viewport.width;

                            // Render PDF page into canvas context
                            let task = await page.render({canvasContext: context, viewport: viewport}).promise;
                            let canvas_data = canvas.toDataURL('image/jpeg');

                            let img_obj = {
                                image: canvas_data,
                                width: (pdfMake.pageLayout.width)
                            };

                            pdf_pages.push(img_obj);
                        }

                        resolve(pdf_pages);
                    });

                    reader.readAsDataURL(file);
                });
            },
            // get object with pdf styles
            pdfStyles() {
                return {
                    header_text: {
                        fontSize: 10,
                        bold: true,
                        alignment: 'right',
                        color: '#2a3991'
                    },
                    code: {
                        fontSize: 14,
                        bold: true,
                    },
                    header: {
                        bold: true,
                        alignment: 'center'
                    },
                    field_left: {
                        fontSize: 12,
                        bold: true
                    },
                    'html-p': {
                        fontSize: 12,
                    },
                    'ql-align-center': {
                        alignment: 'center'
                    },

                    footer: {
                        background: '#ff0000',
                        border: '1px'
                    },
                };
            },
            // get pdf header object
            pdfHeader() {
                let logo_canvas = this.createImageCanvas('logo_img');

                let header_text =
                    this.print_item.company.address + '\n' +
                    this.print_item.company.postcode + ' ' + this.print_item.company.city + ' ' + this.print_item.company.country + '\n' +
                    'Mob. Tel.: ' + this.print_item.company.mobile_number + '\n' +
                    'Tel.: ' + this.print_item.company.phone_number + '\n' +
                    'Email: ' + this.print_item.company.email + '\n' +
                    'VAT: ' + this.print_item.company.vat;

                return {
                    alignment: 'justify',
                    columns: [
                        {image: logo_canvas.toDataURL('image/png'), width: 150, margin: [-9, 0, 0, 0]},
                        {width: '*', text: header_text, style: 'header_text'},
                    ],
                    margin: [40, 40, 40, 15],
                    style: {fillColor: 'black'}
                };
            },

            // print pdf
            async submitPrintDialog() {

                this.print_dialog_loading = true;

                let response = await this.$store.dispatch('apiRequest', {
                    method: 'get', url: `${this.url}/${this.print_item.id}`,
                    params: {
                        action: 'print_incident',
                        is_update: false
                    }
                });

                let incident = response.data;

                let pdf_document = {
                    info: {title: `${incident.code}`, download: `${incident.code}.pdf`},
                };

                // at least one pdf is selected
                if (this.patient_info_pdf || this.medical_report_pdf || this.cost_info_pdf || this.print_invoice) {

                    // create pdf
//                    pdf_document.pageMargins = [40, 124, 40, 80];
                    pdf_document.pageMargins = [40, 124, 40, 40];
                    pdf_document.header = this.pdfHeader();
                    pdf_document.content = [];
                    pdf_document.styles = this.pdfStyles();
                    pdf_document.defaultStyle = {columnGap: 5};

                    let complete_document_promise = new Promise(async (resolve, reject) => {

                        // add patient info to the document
                        if (this.patient_info_pdf) {
                            this.generatePatientInfo(incident, this.medical_report_pdf || this.cost_info_pdf || this.print_invoice)
                                .forEach(obj => pdf_document.content.push(obj));

                            if (!(this.medical_report_pdf || this.cost_info_pdf || this.print_invoice)) {
                                resolve();
                            }
                        }

                        // generate medical report pdf doc
                        if (this.medical_report_pdf) {
                            let result = await this.generateMedicalReport(incident);

                            if (result.image) {
                                result.content.push({
                                    alignment: 'justify',
                                    columns: [
                                        {width: '*', text: ''},
                                        {width: '170', image: result.image}
                                    ],
                                    margin: [0, 50, 0, 10]
                                });

                            } else {

                                if (result.text) {
                                    let html = htmlToPdfmake(result.text);
                                    let list = [];

                                    html.forEach(item => {
                                        item.margin = [0, 0, 0, 0];
                                        list.push(item);
                                    });

                                    result.content.push({
                                        alignment: 'justify',
                                        columns: [
                                            {width: '*', text: ''},
                                            {width: '170', type: 'none', ul: list}
                                        ],
                                        margin: [0, 50, 0, 10]
                                    });
                                }
                            }

                            // add new page
                            if (this.cost_info_pdf || this.print_invoice) {
                                result.content[result.content.length - 1].pageBreak = 'after';
                            }

                            // add medical report content to document
                            result.content.forEach(obj => pdf_document.content.push(obj));

                            if (!(this.cost_info_pdf || this.print_invoice)) {
                                resolve();
                            }
                        }

                        // generate cost info pdf doc
                        if (this.cost_info_pdf) {
                            this.generateCostInfo(incident, this.print_invoice)
                                .forEach(obj => pdf_document.content.push(obj));

                            if (!this.print_invoice) {
                                resolve();
                            }
                        }

                        // generate invoice pdf doc
                        if (this.print_invoice) {

                            this.generateInvoiceInfo(incident, false)
                                .forEach(obj => pdf_document.content.push(obj));

                            resolve();
                        }
                    });

                    // print document
                    complete_document_promise.then(() => {
                        pdfMake.createPdf(pdf_document).open();
                        this.closePrintDialog();
                    });

                } else {

                    pdfMake.pageLayout = {
                        height: 756.00,
                        width: 521.86,
                        margins: [0, 0, 0, 0]
                    };

                    pdf_document.content = [];


                    if (incident.files) {
                        let attachments = [];

                        // get attachments from incidents files
                        incident.files.forEach(obj => {
                            attachments.push(obj)
                        });

                        // loop all attachments
                        for (let i = 0; i < attachments.length; i++) {

                            // get file from API
                            let new_file = await this.$store.dispatch('getAPIFile', {
                                url: `incidents/file/${attachments[i].id}`, file: attachments[i]
                            });

                            // pdf file
                            if (new_file.type === 'application/pdf') {

                                let pages = await this.readPDF(new_file);

                                for (let j = 0; j < pages.length; j++) {

                                    // check if have to enter new page
                                    if (j === pages.length - 1 && i !== attachments.length - 1) {
                                        pages[j].pageBreak = 'after';
                                    }

                                    // add page to pdf
                                    pdf_document.content.push(pages[j]);
                                }

                                // image file
                            } else {

                                let result = await this.readImage(new_file);
                                let content_obj = {image: result, width: (pdfMake.pageLayout.width - 40 - 40)};

                                // check if have to enter new page
                                if (i !== attachments.length - 1) {
                                    content_obj.pageBreak = 'after';
                                }

                                // add image to pdf
                                pdf_document.content.push(content_obj);
                            }
                        }
                    }

                    pdfMake.createPdf(pdf_document).open();
                    this.closePrintDialog();
                }
            }
        }
    }
</script>